import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { LocalStorageUtils } from 'app/core/common/local-storage.utils';

@Component({
    selector: 'eco-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {

    pendingTime = 2;
    interval;
    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    ngOnInit(): void {
        this.interval = setInterval(() => {
            this.pendingTime -= 1;
            if (this.pendingTime == 0)
                this.clearIntervalAfterTime();
        }, 1000);
    }

    clearIntervalAfterTime() {
        clearInterval(this.interval);
        this.redirectHomePage();
    }

    goBacktoSignIn() {
        this.router.navigate(['sign-in']);
    }

    redirectHomePage() {
        const currentLoggedInRole = this.authService.role;
        const imperonsate = LocalStorageUtils.impersonate;

        if (
            imperonsate &&
            (currentLoggedInRole === 'superAdmin' || currentLoggedInRole === 'admin')
        ) {
            this.router.navigate(['/dashboard/channel-status']);
        } else if (currentLoggedInRole === 'superAdmin') {
            this.router.navigate(['/admin/companies/list']);
        } else if (currentLoggedInRole === 'admin') {
            this.router.navigate(['/admin/companies/list']);
        } else if (currentLoggedInRole === 'user') {
            this.router.navigate(['/dashboard/channel-status']);
        } else if (currentLoggedInRole === 'masterUser') {
            this.router.navigate(['/dashboard/channel-status']);
        }
    }
}
