import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private defaultTitle: string = 'eCommify';
  private appTitle: string = environment.appTitle; // Set title based on environment

  constructor(private titleService: Title) {}

  /**
   * Set the title with an optional company name and page title
   * @param companyName The name of the company to include in the title
   * @param pageTitle An optional title for the current page
   */
  setTitle(companyName?: string, pageTitle?: string) {
    let fullTitle = this.defaultTitle;

    if (companyName) {
      fullTitle = `${companyName} - ${fullTitle}`;
    }

    if (pageTitle) {
      fullTitle = `${this.appTitle} ${pageTitle} - ${fullTitle}`;
    }

    this.titleService.setTitle(fullTitle);
  }
}