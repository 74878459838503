<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0">
    <div class="w-full sm:w-auto py-8 px-4 sm:p-12 sm:rounded-2xl sm:shadow sm:bg-card">
        <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
            <!-- Logo -->
            <div class="w-12 mx-auto">
                <img src="assets/images/logo/logo.svg" />
            </div>

            <!-- Title -->
            <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight text-center">
                Oops. Redirecting...
            </div>
            <div class="flex justify-center mt-0.5 font-medium"></div>
            <!-- Form footer -->
            <div class="mt-8 text-md font-medium text-secondary text-center">
                <span>
                    Redirect in {{pendingTime}} {{pendingTime > 1 ? 'seconds' : 'second'}}
                </span>
                <!-- <span>Go to</span> -->
                <!-- <a
          class="ml-1 text-primary-500 hover:underline"
          [routerLink]="['/sign-in']"
          >Back
        </a>
        <span>or</span> -->
            </div>
        </div>
    </div>
</div>
