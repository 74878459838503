import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { TitleService } from './services/title.service'; // Adjust path as needed
import { UserService } from './core/user/user.service'; // Adjust path as needed
import { LocalStorageUtils } from './core/common/local-storage.utils'; // Adjust path as needed

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  companyName: string = ''; // Default to an empty string

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: TitleService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    // Subscribe to user observable to get company name
    this.userService.user$.pipe(
      tap(user => {
        if (LocalStorageUtils.impersonate === 'true') {
          this.companyName = LocalStorageUtils.companyName;
        } else {
          this.companyName = user?.companies?.[0]?.company_name || '';
        }
      })
    ).subscribe(() => this.updateTitle()); // Ensure title is updated after companyName is set

    // Listen for route changes and set the title
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        mergeMap(route => route.data),
        map(data => data['title'])
      )
      .subscribe((pageTitle: string | undefined) => {
        this.updateTitle(pageTitle);
      });
  }

  private updateTitle(pageTitle?: string): void {
    this.titleService.setTitle(this.companyName, pageTitle);
  }
}